// import { forwardRef } from "react";
// import { Link as RouterLink } from "react-router-dom";
// // @mui
// import { useTheme } from "@mui/material/styles";
// import { Box, Link, BoxProps } from "@mui/material";
// import Image from "../image/Image";
// import neo from "../../assets/logo/neo-Neosprint_logo_black.svg";
// import tramo from "../../assets/logo/tramoTrmao-Final-Logo.svg";

// // ----------------------------------------------------------------------

// export interface LogoProps extends BoxProps {
//   disabledLink?: boolean;
// }

// const Logo = forwardRef<HTMLDivElement, LogoProps>(
//   ({ disabledLink = false, sx, ...other }, ref) => {
//     const theme = useTheme();

//     const PRIMARY_LIGHT = theme.palette.primary.light;

//     const PRIMARY_MAIN = theme.palette.primary.main;

//     const PRIMARY_DARK = theme.palette.primary.dark;

   

//     const logo = (
//       <Box
//         ref={ref}
//         component="div"
//         sx={{
//           width: 120,
//           height: 70,
//           // display: 'inline-flex',
//           mt: 4,
//           ...sx,
//         }}
//         {...other}
//       >
//         <Image src={tramo} alt={"LOGO"} />
      
//       </Box>
//     );

//     if (disabledLink) {
//       return <>{logo}</>;
//     }

//     return (
//       <Link to="/" component={RouterLink} sx={{ display: "contents" }}>
//         {logo}
//       </Link>
//     );
//   }
// );

// export default Logo;



import { forwardRef } from "react";
import { Box, IconButton, BoxProps } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Image from "../image/Image";
import tramo from "../../assets/logo/tramoTrmao-Final-Logo.svg";

// ----------------------------------------------------------------------

interface LogoProps extends BoxProps {
  isCollapsed?: boolean;
  toggleCollapse?: () => void; 
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ isCollapsed = false, toggleCollapse = () => {}, disabledLink = false, sx, ...other }, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isCollapsed ? "center" : "space-between",
          width: "100%",
          padding: "10px",
          ...sx,
        }}
        {...other}
      >
        {/* Logo Section */}
        {!isCollapsed && (
          <Box sx={{ width: 120, height: 70, transition: "opacity 0.3s ease-in-out" }}>
            {disabledLink ? (
              <Image src={tramo} alt="LOGO" />
            ) : (
              <a href="/">
                <Image src={tramo} alt="LOGO" />
              </a>
            )}
          </Box>
        )}

        {/* Collapse Button (Should Always Be Visible) */}
        <IconButton onClick={toggleCollapse} sx={{ marginLeft: "10px" }}>
          <MenuIcon />
        </IconButton>
      </Box>
    );
  }
);

export default Logo;
