// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// // @mui
// import { Box, Stack, Drawer } from "@mui/material";
// // hooks
// import useResponsive from "../../../hooks/useResponsive";
// // config
// import { NAV } from "../../../config";
// // components
// import Logo from "../../../components/logo";
// import Scrollbar from "../../../components/scrollbar";
// import { NavSectionVertical } from "../../../components/nav-section";
// //
// import navConfig from "./config";
// import NavDocs from "./NavDocs";
// import NavAccount from "./NavAccount";
// import Label from "src/components/label/Label";
// import { fIndianCurrency } from "src/utils/formatNumber";
// import { useAuthContext } from "src/auth/useAuthContext";

// // ----------------------------------------------------------------------

// type Props = {
//   openNav: boolean;
//   onCloseNav: VoidFunction;
// };

// export default function NavVertical({ openNav, onCloseNav }: Props) {
//   const { pathname } = useLocation();
//   const { user } = useAuthContext();

//   const isDesktop = useResponsive("up", "lg");
//   const isTablet = useResponsive("up", "sm");

//   const walletStyle = {
//     textTransform: "capitalize",
//     borderColor: "primary",
//     borderRadius: 8,
//     borderWidth: "2px",
//     borderStyle: "solid",
//   };

//   useEffect(() => {
//     if (openNav) {
//       onCloseNav();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [pathname]);

//   const renderContent = (
//     <Scrollbar
//       sx={{
//         height: 1,
//         "& .simplebar-content": {
//           height: 1,
//           display: "flex",
//           flexDirection: "column",
//         },
//       }}
//     >
//       <Stack
//         spacing={3}
//         sx={{
//           pt: 3,
//           pb: 2,
//           px: 2.5,
//           flexShrink: 0,
//         }}
//       >
//         <Logo />

//         {!isTablet && (
//           <>
//             <NavAccount />
//             <Label variant="soft" color={"primary"} sx={walletStyle}>
//               Main = {fIndianCurrency(user?.main_wallet_amount) || 0}
//             </Label>
//             <Label variant="soft" color={"warning"} sx={walletStyle}>
//               Aeps = {fIndianCurrency(user?.AEPS_wallet_amount) || 0}
//             </Label>
//             <Label variant="soft" color={"warning"} sx={walletStyle}>
//               TDS = {fIndianCurrency(user?.TDSWallet) || 0}
//             </Label>
//             <Label variant="soft" color={"warning"} sx={walletStyle}>
//               GST = {fIndianCurrency(user?.GSTWallet) || 0}
//             </Label>
//           </>
//         )}
//       </Stack>

//       <NavSectionVertical data={navConfig} />

//       <Box sx={{ flexGrow: 1 }} />

//       <NavDocs />
//     </Scrollbar>
//   );

//   return (
//     <Box
//       component="nav"
//       sx={{
//         flexShrink: { lg: 0 },
//         width: { lg: NAV.W_DASHBOARD },
//       }}
//     >
//       {isDesktop ? (
//         <Drawer
//           open
//           variant="permanent"
//           PaperProps={{
//             sx: {
//               width: NAV.W_DASHBOARD,
//               bgcolor: "transparent",
//               borderRightStyle: "dashed",
//             },
//           }}
//         >
//           {renderContent}
//         </Drawer>
//       ) : (
//         <Drawer
//           open={openNav}
//           onClose={onCloseNav}
//           ModalProps={{
//             keepMounted: true,
//           }}
//           PaperProps={{
//             sx: {
//               width: NAV.W_DASHBOARD,
//             },
//           }}
//         >
//           {renderContent}
//         </Drawer>
//       )}
//     </Box>
//   );
// }



import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { Box, Stack, Drawer } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// config
import { NAV } from "../../../config";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import { NavSectionVertical } from "../../../components/nav-section";
import navConfig from "./config";

// ----------------------------------------------------------------------

interface Props {
  openNav: boolean;
  onCloseNav: VoidFunction;
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

export default function NavVertical({ openNav, onCloseNav, isCollapsed, toggleCollapse }: Props) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        {/* Logo with Collapse Button */}
        <Logo isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />
      </Stack>

      {/* Navigation Links */}

      {!isCollapsed ? <NavSectionVertical data={navConfig} />:<NavSectionVertical data={navConfig}/>}


      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        // main right isde contain
        width: isCollapsed ? "70px" : NAV.W_DASHBOARD, // Adjust Sidebar Width
        transition: "width 0.3s ease-in-out",
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              // sidebar icons widht
              width: isCollapsed ? "80px" : NAV.W_DASHBOARD,
              bgcolor: "transparent",
              borderRightStyle: "dashed",
              transition: "width 0.3s ease-in-out",


              // whole sidebar background color
              // backgroundColor:'rgb(242, 215, 193)'
              //YEHI HAI

              backgroundColor:' #EDEDED'
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
