// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// i want to add this
import DashboardIcon from '@mui/icons-material/Dashboard';
import InsightsIcon from '@mui/icons-material/Insights';
import ReportIcon from '@mui/icons-material/Report';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import WalletIcon from '@mui/icons-material/Wallet';
import ConstructionIcon from '@mui/icons-material/Construction';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import StorefrontIcon from '@mui/icons-material/Storefront';
import BusinessIcon from '@mui/icons-material/Business';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import HandshakeIcon from '@mui/icons-material/Handshake';


import SyncIcon from '@mui/icons-material/Sync';

import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import BarChartIcon from '@mui/icons-material/BarChart';




// import GroupAddIcon from '@mui/icons-material/GroupAdd';


// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
  ecommerce: icon("ic_ecommerce"),
  // analytics: icon("ic_analytics"),
  dashboard: <DashboardIcon/>,
  insights: <BarChartIcon/>,
  report: <ReportIcon/>,
  vendor:<BusinessIcon/>,
  scheme:<AssignmentTurnedInIcon/>,

  fund:<CurrencyRupeeIcon/>,
  newlead:<GroupAddIcon/>,
  bbps:<ReceiptLongIcon/>,


  group: <HandshakeIcon/>,
  wallet: <WalletIcon/>,
  autocollect:<PublishedWithChangesIcon/>,
  construction: <ConstructionIcon/>,
  search: <ContentPasteSearchIcon/>,
  document: <DocumentScannerIcon/>,
  mail: icon("ic_dashboard"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.dashboard,
        icon: ICONS.dashboard,
      },
      {
        title: "analytics",
        path: PATH_DASHBOARD.analytics,
        icon: ICONS.insights,
      },
      {
        title: "New Leads",
        path: PATH_DASHBOARD.newleads,
        icon: ICONS.newlead,
      },
      {
        title: "Our Network",
        path: PATH_DASHBOARD.ournetwork,
        icon: ICONS.group,
      },
      {
        title: "reports",
        path: PATH_DASHBOARD.reports.root,
        icon: ICONS.report,
        children: [
          {
            title: "All Transaction Records ",
            path: PATH_DASHBOARD.reports.alltransactionrecord,
          },
          {
            title: "Fund Flow Transactions",
            path: PATH_DASHBOARD.reports.fundflow,
          },

          {
            title: "Wallet Ladger ",
            path: PATH_DASHBOARD.reports.WalletLadger,
          },
          {
            title: "Hold duplicate Transaction Limit ",
            path: PATH_DASHBOARD.reports.WaitingAreaForDuplicateTxn,
          },
          {
            title: "Historical Data Exports ",
            path: PATH_DASHBOARD.reports.HistoricalDataExport,
          },
          {
            title: "Instant Report Download",
            path: PATH_DASHBOARD.reports.AccountStatement,
          },
          // {
          //   title: " TDS & GST New Historical Report",
          //   path: PATH_DASHBOARD.reports.TdsGstReport,
          // },
          {
            title: "UserWise Opening Balance",
            path: PATH_DASHBOARD.reports.UserWiseClosingBal,
          },
          {
            title: "GST Ladger ",
            path: PATH_DASHBOARD.reports.GSTLadger,
          },
          {
            title: "TDS Ladger ",
            path: PATH_DASHBOARD.reports.TDSLadger,
          },
          {
            title: "Temporary Report Export ",
            path: PATH_DASHBOARD.reports.Temporaryreport,
          },
        ],
      },
      {
        title: "Scheme Management",
        path: PATH_DASHBOARD.scheme.root,
        icon: ICONS.scheme,
        children: [
          { title: "Manage Scheme", path: PATH_DASHBOARD.scheme.allscheme },
          { title: "Map Scheme", path: PATH_DASHBOARD.scheme.MapSchemeSetting },
          {
            title: "Manage BBPS Scheme",
            path: PATH_DASHBOARD.scheme.AllbbpsScheme,
          },
          {
            title: "Map BBPS Scheme",
            path: PATH_DASHBOARD.scheme.mapbbpsScheme,
          },
          {
            title: "Manage Loan Scheme",
            path: PATH_DASHBOARD.scheme.AllLaonScheme,
          },
          {
            title: "Map Loan Scheme",
            path: PATH_DASHBOARD.scheme.maploanscheme,
          },
        ],
      },
      // {
      //   title: "Product Managments",
      //   path: PATH_DASHBOARD.product.root,
      //   icon: ICONS.mail,
      //   children: [
      //     {
      //       title: "Add New Product",
      //       path: PATH_DASHBOARD.product.productmanagement,
      //     },
      //     {
      //       title: "Assign Vendor",
      //       path: PATH_DASHBOARD.product.assignvendor,
      //     },
      //     {
      //       title: "Map Short Code",
      //       path: PATH_DASHBOARD.product.mapshortcode,
      //     },
      //   ],
      // },
      {
        title: "Vendor Management",
        path: PATH_DASHBOARD.vendor.root,
        icon: ICONS.vendor,
        children: [
          {
            title: "Add New Vendor",
            path: PATH_DASHBOARD.vendor.vendormanagement,
          },
          {
            title: "Credit Card Slots",
            path: PATH_DASHBOARD.vendor.creditcardslots,
          },
          {
            title: "Money Transfer Slots",
            path: PATH_DASHBOARD.vendor.moneytransferslots,
          },
          {
            title: "Vendor Payment Slots",
            path: PATH_DASHBOARD.vendor.vendorpaymentslots,
          },
          {
            title: "DMT1 Slots",
            path: PATH_DASHBOARD.vendor.dmtslots,
          },
          {
            title: "DMT2 Slots",
            path: PATH_DASHBOARD.vendor.dmt2slots,
          },
          {
            title: "AEPS Slots",
            path: PATH_DASHBOARD.vendor.aepsslots,
          },
          {
            title: "BBPS Slots",
            path: PATH_DASHBOARD.vendor.bbpslots,
          },
          {
            title: "Plan Fetch Records",
            path: PATH_DASHBOARD.vendor.mplan,
          },
          {
            title: "Transfer Slots",
            path: PATH_DASHBOARD.vendor.transferslots,
          },
          {
            title: "Payout Slots",
            path: PATH_DASHBOARD.vendor.payoutslots,
          },
          {
            title: "ADMT Slots",
            path: PATH_DASHBOARD.vendor.admtslots,
          },
        ],
      },
      {
        title: "Fund Management",
        path: PATH_DASHBOARD.fundmanagement.root,
        icon: ICONS.fund,
        children: [
          {
            title: "Add New Bank",
            path: PATH_DASHBOARD.fundmanagement.addbank,
          },
          {
            title: "Admin Fund Flow",
            path: PATH_DASHBOARD.fundmanagement.adminfundflow,
          },
          {
            title: "Fund Requests",
            path: PATH_DASHBOARD.fundmanagement.fundrequest,
          },
        ],
      },
      {
        title: "Wallet Management",
        path: PATH_DASHBOARD.walletmanagement.root,
        icon: ICONS.wallet,
        children: [
          {
            title: "Main Wallet",
            path: PATH_DASHBOARD.walletmanagement.mainwallet,
          },
          {
            title: "TDS Wallet",
            path: PATH_DASHBOARD.walletmanagement.tdswallet,
          },
          {
            title: "GST Wallet",
            path: PATH_DASHBOARD.walletmanagement.gstwallet,
          },
        ],
      },
      // {
      //   title: "BBPS Management",
      //   path: PATH_DASHBOARD.bbps.bbpsmanagement,
      //   icon: ICONS.dashboard,
      // },

      {
        title: "BBPS Management",
        path: PATH_DASHBOARD.bbps.bbpsmamagements,
        icon: ICONS.bbps,
        children: [
          {
            title: "BBPS",
            path: PATH_DASHBOARD.bbps.bbpsmamagements,
          },
          {
            title: "BBPS Products",
            path: PATH_DASHBOARD.bbps.bbpsproducts,
          },
        ],
      },

      {
        title: "Auto Collect",
        path: PATH_DASHBOARD.autocollect.addautocollect,
        icon: ICONS.autocollect,
        children: [
          {
            title: "Add Auto Collect Services",
            path: PATH_DASHBOARD.autocollect.addautocollect,
          },
          {
            title: "Services",
            path: PATH_DASHBOARD.autocollect.services,
          },
          {
            title: "Auto Collect Table",
            path: PATH_DASHBOARD.autocollect.autocollecttable,
          },
        ],
      },

      // {
      //   title: "Roll Management",
      //   path: PATH_DASHBOARD.rollmanagement,
      //   icon: ICONS.dashboard,
      // },

      {
        title: "Content Management",
        path: PATH_DASHBOARD.contentmanagement.root,
        icon: ICONS.search,
        children: [
          {
            title: "FAQ Management",
            path: PATH_DASHBOARD.contentmanagement.faqmanagement,
          },
          {
            title: "Update Images",
            path: PATH_DASHBOARD.contentmanagement.updateimage,
          },
        ],
      },
      {
        title: "Tools",
        path: PATH_DASHBOARD.tools.root,
        icon: ICONS.construction,
        children: [
          {
            title: "SMS/Email Management ",
            path: PATH_DASHBOARD.tools.smsmanagement,
          },
          { title: "Approve User PAN", path: PATH_DASHBOARD.tools.panvarified },
          { title: "Update DocuSign", path: PATH_DASHBOARD.tools.docusign },
          { title: "Vendor Switch ", path: PATH_DASHBOARD.tools.vendorswitch },
          { title: "Bank Master ", path: PATH_DASHBOARD.tools.bankmaster },
          {
            title: "Enable/Disable categories",
            path: PATH_DASHBOARD.tools.enablediscategories,
          },
          {
            title: "Wallet To Wallet Control",
            path: PATH_DASHBOARD.tools.walletToWallet,
          },
          { title: "AEPS", path: PATH_DASHBOARD.tools.aeps },
          { title: "Other ", path: PATH_DASHBOARD.tools.Other },
        ],
      },

      // {
      //   title: "testreports",
      //   path: PATH_DASHBOARD.testreports.root,
      //   icon: ICONS.mail,
      //   children: [
      //     {
      //       title: "All Transaction Records",
      //       path: PATH_DASHBOARD.testreports.alltransactionrecordtest,
      //     },
      //     {
      //       title: "Fund Reports",
      //       path: PATH_DASHBOARD.testreports.fundreports,
      //     },
      //   ],
      // },
      {
        title: "Doc Api Reference",
        path: PATH_DASHBOARD.docapireference,
        icon: ICONS.document,
      },
      // {
      //   title: "Sales Management",
      //   path: PATH_DASHBOARD.salesmanagement,
      //   icon: ICONS.dashboard,
      // },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: "management",
  //   items: [
  //     {
  //       title: "user",
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: "Four", path: PATH_DASHBOARD.user.four },
  //         { title: "Five", path: PATH_DASHBOARD.user.five },
  //         { title: "Six", path: PATH_DASHBOARD.user.six },
  //       ],
  //     },
  //   ],
  // },
];

export default navConfig;
